import Layout from "../components/layout"
import {graphql} from 'gatsby'
import React from 'react'
import Link from 'gatsby-link'
import '../layouts/trip.styl'
import BodyClassName from 'react-body-classname';
import Img from "gatsby-image"
import PageTransition from 'gatsby-v2-plugin-page-transitions'

export default class TripsIndexPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: trips } = data.allMarkdownRemark

    return (
      <Layout>
      <PageTransition>
      <BodyClassName className="trip">
      <div>
      

      <article className="">
      <div className="tripGrid">
        {trips
          .map(({ node: trip }) => (
            <div className="tripItem trip grow" key={trip.id}>
              <Link to={trip.fields.slug}>
              <Img fluid={trip.frontmatter.thumb.childImageSharp.fluid} />
              <h2>{trip.frontmatter.title}</h2>
              </Link>
            </div>
          ))}
      </div>
      </article>
      </div>
      </BodyClassName>
      </PageTransition>
      </Layout>
    )
  }
}

export const TripsQuery = graphql`
  query TripIndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "trip" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          timeToRead
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "MMMM DD, YYYY")
            collection
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
